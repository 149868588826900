import React from 'react'

const CarbonFootprint = (props) => {
    const { totalCarbon, currentStep } = props;
    return (
      <div>
        {currentStep >= 2 && (
          <div className="my-3">
            <div
              className="d-flex align-items-center p-3 justify-content-between text-black px-3 rounded"
              style={{ backgroundColor: "#DCFED3" }}
            >
              <p className="text-bold m-0">
                Your Carbon Footprint : KG-COe
                {/* //CO<sub>2</sub> */}
              </p>
              <p className='m-0 text-lead text-bold'>{Math.ceil(totalCarbon) || "0.00"}</p>
            </div>
          </div>
        )}
      </div>
    );
}

export default CarbonFootprint