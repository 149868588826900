import React from 'react'
import WebbDividerSmall from '../webx/webb-divider-sm';

const ProgressBar = (props) => {
    const { progress, currentStep, totalSteps } = props;
  return (
    <div className={ currentStep >= 4 ? "mt-3 d-none" : ""}>
      {/* <WebbDividerSmall /> */}
      <p className="m-0 mb-1">
        Progress : {currentStep}/{totalSteps}
      </p>
      <div className="progress" style={{ height: "0.40rem" }}>
        <div
          className="progress-bar progress-bar-striped progress-bar-animated back-color-success"
          role="progressbar"
          aria-label="Basic example"
          style={{ width: progress }}
          aria-valuenow={((currentStep + 1) * 100) / totalSteps}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
}

export default ProgressBar