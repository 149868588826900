import * as Realm from "realm-web";
import axios from "axios";

const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/terano-accounts-xzosf/endpoint";

// read functions
export const AccountsMinterInit = async (item) => {
  const basx = base + `/accounts/minter/init`;
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = item;
  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return {
      code: result.status,
      stat: result.data.stat,
      data: result.data.data,
    };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};


