import React from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import WebbDividerSmall from "../webx/webb-divider-sm";
import { AerolanePortsList } from "../../services/algorand-summit/port-list";
import { CarbonFootprintCalculator } from "../../services/algorand-summit/icao-calculate";

const AirlineModule = (props) => {
  const { handleBack, handleNext, stepCount, setAirtravelCarbon } = props;
  const [portListA, setPortListA] = useState([]);
  const [portListB, setPortListB] = useState([]);
  const [searchTermA, setSearchTermA] = useState("");
  const [memo, setMemo] = useState("");
  const [loading, setLoading] = useState(true);
  const [icao, setIcao] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [departure, setDeparture] = useState([]);
  const [destination, setDestination] = useState([]);
  const [formValues, setFormValues] = useState({
    selectedPortA: "",
    selectedPortB: "",
    numPassengers: 1,
    cabinClass: "Economy",
    tripType: "One way",
    destinations: [],
  });

  const [unitType, setUnitType] = useState("Metric");
  // console.log("Form values", formValues);
  useEffect(() => {
    const fetchdata = async () => {
      setLoading(true);
      const result = await AerolanePortsList({
        data: { search: searchTermA || "" },
        srvc: "******",
      });
      if (result.stat) {
        setPortListA(result.data.list);
        setPortListB(result.data.list);
      }
      setLoading(false);
    };
    fetchdata();
  }, []);


  useEffect(() => {
    // Set the options for the country dropdown
    const options = portListA.map((airport) => ({
      label: airport.name,
      value: airport.code,
    }));
    setDeparture(options);
    setShowResult(false);
  }, [portListA]);

  useEffect(() => {
    // Set the options for the country dropdown
    const options = portListB.map((airport) => ({
      label: airport.name,
      value: airport.code,
    }));
    setDestination(options);
  }, [portListB]);

  // console.log("PRTLISTA", formValues)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleButtonClick = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const fetchdata = async () => {
    setLoading(true);
    setSubmit(true);
    setMemo("Please wait ....");
    // console.log("search term", searchTermA, searchTermB);
    const datx = {
      lost: { code: formValues.selectedPortA },
      lods: { code: formValues.selectedPortB },
      passenger_count: formValues.numPassengers,
      trip: formValues.tripType === "Round Trip" ? 2 : 1,
      class_type: formValues.cabinClass,
    };

    const result = await CarbonFootprintCalculator({
        data: datx,
        srvc: "******",
      });
    if (result.stat) {
      setIcao(result.data);
      setAirtravelCarbon(result.data.carb.number);
      setMemo("");
      handleNext();
    }
    setLoading(false);
  };

  const handleCalculate = () => {
    if (formValues.selectedPortA && formValues.selectedPortB) {
      fetchdata();
    } else {
      handleNext();
    }
    // setShowResult(true);
  };
  // console.log("CALCULATE", icao);

  const handleDecrement = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      numPassengers: Math.max(prevValues.numPassengers - 1, 1),
    }));
  };

  const handleIncrement = () => {
    setFormValues((prevValues) => ({
      ...prevValues,
      numPassengers: prevValues.numPassengers + 1,
    }));
  };

  const handleChange = async (key, val) => {
    setShowResult(false);
    setFormValues({ ...formValues, [key]: val });
  };

  const handleChangePort = (field, value) => {
    if (field === "selectedPortA" && value === formValues.selectedPortB) {
       alert("The departure and destination ports cannot be the same.");
      return;
    }

    if (field === "selectedPortB" && value === formValues.selectedPortA) {
       alert("The departure and destination ports cannot be the same.");
      return;
    }
    // setMemo("")
    setFormValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      <br />
      <div className="container-fluid rounded border">
        <div className="container pb-4">
          <div className=" align-items-center gap-5 ">
            <div>
              <form>
                <div className="form-group pt-4">
                  <label
                    htmlFor="departureSelect"
                    className="text-bold"
                    style={{ color: "black", fontSize: "15px" }}
                  >
                    From
                  </label>
                  <Select
                    className="mt-1 text-small"
                    options={departure}
                    value={departure.find(
                      (option) => option.value === formValues?.selectedPortA
                    )}
                    onChange={(selectedOption) =>
                      handleChangePort("selectedPortA", selectedOption.value)
                    }
                    isDisabled={loader}
                    placeholder="Select departure..."
                    isSearchable
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",
                        fontSize: "0.9rem",
                        height: "2.7rem",
                        minHeight: "2.7rem",
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: "10rem",
                        overflowY: "auto",
                      }),
                      option: (base) => ({
                        ...base,
                        fontSize: "0.8rem",
                        padding: "0.4rem 0.6rem",
                      }),
                    }}
                  />
                </div>
                <div className="form-group mt-3">
                  <label
                    htmlFor="departureSelect"
                    className="text-bold"
                    style={{ color: "black", fontSize: "15px" }}
                  >
                    To
                  </label>
                  <Select
                    className="mt-1 text-small"
                    options={destination}
                    value={destination.find(
                      (option) => option.value === formValues?.selectedPortB
                    )}
                    onChange={(selectedOption) =>
                      handleChangePort("selectedPortB", selectedOption.value)
                    }
                    isDisabled={loader}
                    placeholder="Select destination"
                    isSearchable
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "100%",
                        fontSize: "0.9rem",
                        height: "2.7rem",
                        minHeight: "2.7rem",
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                      menuList: (base) => ({
                        ...base,
                        maxHeight: "10rem",
                        overflowY: "auto",
                      }),
                      option: (base) => ({
                        ...base,
                        fontSize: "0.8rem",
                        padding: "0.4rem 0.6rem",
                      }),
                    }}
                  />
                </div>

                <hr />
                <div className="row">
                  <div className="col-md-6 d-flex flex-column gap-2">
                    <label className="text-bold ">Cabin Class</label>
                    <div>
                      <button
                        type="button"
                        className={` border-0 text-small text-bold rounded ${
                          formValues.cabinClass === "Economy"
                            ? "text-white"
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            formValues.cabinClass === "Economy"
                              ? "#063902"
                              : "#DCFED3",
                          color:
                            formValues.cabinClass === "Economy"
                              ? "#fff"
                              : "#063902",
                          // width: "80px",
                          fontSize: "12px",
                        }}
                        onClick={() =>
                          handleButtonClick("cabinClass", "Economy")
                        }
                      >
                        Economy
                      </button>
                      <button
                        type="button"
                        className={`border-0 text-bold ms-2 rounded ${
                          formValues.cabinClass === "Premium"
                            ? "text-white"
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            formValues.cabinClass === "Premium"
                              ? "#063902"
                              : "#DCFED3",
                          color:
                            formValues.cabinClass === "Premium"
                              ? "#fff"
                              : "#063902",
                          fontSize: "12px",
                        }}
                        onClick={() =>
                          handleButtonClick("cabinClass", "Premium")
                        }
                      >
                        Premium
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex flex-column gap-2 mt-lg-0 mt-3">
                    <label className="text-bold ">Trip</label>
                    <div>
                      <button
                        type="button"
                        className={`border-0 text-bold ms-2 rounded text-small text-nowrap ${
                          formValues.tripType === "Round Trip"
                            ? "text-white"
                            : ""
                        }`}
                        style={{
                          backgroundColor:
                            formValues.tripType === "Round Trip"
                              ? "#063902"
                              : "#DCFED3",
                          color:
                            formValues.tripType === "Round Trip"
                              ? "#fff"
                              : "#063902",
                          fontSize: "12px",
                        }}
                        onClick={() =>
                          handleButtonClick("tripType", "Round Trip")
                        }
                      >
                        Round Trip
                      </button>
                      <button
                        type="button"
                        className={`border-0 text-bold ms-2 text-nowrap  text-small  rounded ${
                          formValues.tripType === "One way" ? "text-white" : ""
                        }`}
                        style={{
                          backgroundColor:
                            formValues.tripType === "One way"
                              ? "#063902"
                              : "#DCFED3",
                          color:
                            formValues.tripType === "One way"
                              ? "#fff"
                              : "#063902",
                          fontSize: "12px",
                        }}
                        onClick={() => handleButtonClick("tripType", "One way")}
                      >
                        One way
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <WebbDividerSmall />
              <p className="text-small m-0">{memo}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="d-flex align-items-center justify-content-between">
          {stepCount !== 0 && (
            <button
              onClick={() => handleBack()}
              className="btn rounded-xx btn-outline-secondary text-small mt-3 w-10 "
              type="submit"
            >
              Back
            </button>
          )}

          <button
            onClick={handleCalculate}
            className="btn rounded-xx btn-success text-small float-end mt-3 w-10 "
            type="submit"
            disabled={!formValues.selectedPortA && !formValues.selectedPortB}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default AirlineModule;
