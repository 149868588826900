import { useState } from "react";

export default function AttendenceModeModule(props) {
  const { handleBack, handleNext, stepchange, stepCount } = props;
  const [selectedValue, setSelectedValue] = useState("");

  const handleClick =  () => {
    const radios = document.getElementsByName("attendanceMode");

    // Loop through and find the checked radio button
    for (const radio of radios) {
      if (radio.checked) {
        setSelectedValue(radio.value);
        break;
      }
    }

    // props.change("mode", selectedValue);

    // if (selectedValue == "online") {
    //   props.stepchange(6);
    // }
    // if (selectedValue == "offline") {
    //     stepchange(2);
    // }
  };

  return (
    <>
      <br />
      <form className="border p-3 rounded">
        <div className="form-group">
          <label>
            <input
              type="radio"
              name="attendanceMode"
              value="offline"
              onChange={handleClick}
              required
              checked
            />
            <span className="mx-3">Offline</span>
          </label>
        </div>
        <div className="form-group d-none">
          <label>
            <input type="radio" name="attendanceMode" value="online" required />
            <span className="mx-3">Online</span>
          </label>
        </div>
      </form>

      <div className="mt-2">
        <div className="d-flex align-items-center justify-content-between">
          {stepCount !== 0 && (
            <div className="me-auto">
            <button
              onClick={() => handleBack()}
              className="btn rounded-xx btn-outline-secondary text-small mt-3 w-10  d-none"
              type="submit"
            >
              Back
            </button>
            </div>
          )}

          <button
            onClick={handleNext}
            className="btn rounded-xx btn-success text-small float-end mt-3 w-10 float-end "
            type="submit"
           // disabled={!selectedValue}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
