"use client";
import React, { useEffect, useState } from "react";
import countries from "../../data.static/data-country-code.json";
import Select from "react-select";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import WebbDividerMedium from "../webx/webb-divider-md";
import { UserAccountCreate } from "../../services/algorand-summit/user-account-create-x";

const RegistrationModule = (props) => {
  const { stepchange, handleNext } = props;
  const [memo, setMemo] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [data, setData] = useState({
    name: "",
    mail: "",
    mobile: "",
    countryCode: "",
  });

  useEffect(() => {
    // Set the options for the country dropdown
    const options = countries.map((country) => ({
      label: country.name,
      value: country.code,
    }));
    setCountryOptions(options);
  }, []);

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait ...");

    const datx = {
      name: data.name,
      mail: data.mail,
      mobile: data.mobile,
      countryCode: data.countryCode,
      active: true,
    };

    const res = await UserAccountCreate({ data: datx, srvc: "******" });

    if (res.data) {
      setMemo("User Onboarding");
      localStorage.setItem("indx", JSON.stringify(res.data));

      setDone(true);
      handleNext();
    }
    else {
      setMemo("Account Not Created");

      if (res.memo == "user account exist")
        setMemo("Account Not Created, Duplicate Email");

      setDone(true);
      handleNext();
    }
  };

  return (
    <>
      <div className="">
        <WebbDividerSmall />
        <div className="border p-3 rounded">
          <label className="text-small">
            Full Name <FormNeeded />
          </label>
          <input
            className="form-control mt-1 mb-3"
            onChange={({ target }) => {
              handleChange("name", target.value);
            }}
          />

          <label className="text-small">
            Email Address <FormNeeded />
          </label>
          <input
            className="form-control mt-1 mb-3"
            onChange={({ target }) => {
              handleChange("mail", target.value);
            }}
          />

          {/* <label className="text-small">Mobile <FormNeeded /></label>
                <input className="form-control mt-1 mb-3"
                onChange={({ target }) => {
                    handleChange("mobile", target.value);
                }} 
                /> */}

          <label className="text-small">
            Location () <FormNeeded />
          </label>
          <Select
            className="mt-1 text-small"
            options={countryOptions}
            value={countryOptions.find(
              (option) => option.value === data?.countryName
            )}
            onChange={(selectedOption) =>
              handleChange("countryCode", selectedOption.value)
            }
            isDisabled={loader}
            placeholder="Select Country"
            isSearchable
            styles={{
              control: (base) => ({
                ...base,
                width: "100%",
                fontSize: "0.9rem",
                height: "2.7rem",
                minHeight: "2.7rem",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: "10rem",
                overflowY: "auto",
              }),
              option: (base) => ({
                ...base,
                fontSize: "0.8rem",
                padding: "0.4rem 0.6rem",
              }),
            }}
          />

          <WebbDividerSmall />
          <p className="text-small m-0">{memo}</p>
          <WebbDividerMedium />

          <button
            onClick={handleSubmit}
            className={
              submit ? "d-none" : "btn btn-success w-100 text-small rounded-xx"
            }
            disabled={!data.name || !data.mail || !data.countryCode}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default RegistrationModule;
