// // Next.js API route support: https://nextjs.org/docs/api-routes/introduction
// import axios from "axios";

// // const base = 'https://ap-southeast-1.aws.data.mongodb-api.com/app/terano-transfers-xrcie/endpoint'
// const base =
//   "https://ap-southeast-1.aws.data.mongodb-api.com/app/terano-transfers-kfwwf/endpoint";

// export default async (req, res) => {
//   const basx = base + `/transfers/funds/create`;
//   const head = {
//     "Content-Type": "application/json",
//     Authorization: process.env.WEBB_SITE_CLNT,
//   };
//   const datx = { data: req.body, srvc: process.env.WEBB_SITE_SRVC };

//   var result;
//   try {
//     result = await axios.post(basx, datx, { headers: head });
//     // console.log(result.status);
//     // console.log(JSON.stringify(result.data));
//     res.status(result.status).json(JSON.stringify(result.data));
//   } catch (error) {
//     // console.log(error.response.data);
//     res.status(error.response.status).json(JSON.stringify(error.response.data));
//   }
// };


import * as Realm from "realm-web";
import axios from "axios";

const base =
  "https://ap-southeast-1.aws.data.mongodb-api.com/app/terano-transfers-kfwwf/endpoint";

// read functions
export const TransferFundsCreate = async (item) => {
  const basx = base + `/transfers/funds/create`;
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  var result;
  try {
    result = await axios.post(basx, datx, { headers: head });
    return {
      code: result.status,
      stat: result.data.stat,
      data: result.data.data,
    };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
};



