// content
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import WebbDividerMedium from "../../src/content/webx/webb-divider-md";
import WebbDividerSmall from "../../src/content/webx/webb-divider-sm";

const media = '/terano-main.png'
const banner = 'https://img.freepik.com/free-photo/wide-shot-group-penguins-tall-iceberg_181624-3338.jpg?w=900'

const icon = require('../data.media/terano-icon.png')

const metadata = {
  name: 'Arctic',
  memo: process.env.NEXT_PUBLIC_WEBB_SITE_LINE,
  words: process.env.NEXT_PUBLIC_WEBB_SITE_WORDS,
  banner: {link: banner}, // {link: process.env.NEXT_PUBLIC_WEBB_SITE_MEDIA},
  media: {link: banner}, // {link: process.env.NEXT_PUBLIC_WEBB_SITE_MEDIA},
  created: '2024-09-01T05:00:00.000Z',
  modified: '2024-09-01T06:00:00.000Z',
  domain: 'main'
}

export default function Content() {

  const [user, setuser] = useState({
    name: '', mail: '', item: ''
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState(0)

  const getCount = (number) => {
    setCount(number)
  }

  return (
    <>
      <Helmet>
        <title>{`${metadata?.name} • ${metadata.memo}`}</title>
        <meta
          name="description"
          content={`'Buy and Sell Regsitry Verified Carbon Credits: '+${metadata.memo}`}
        ></meta>
        <meta property="og:image" content={metadata.media.link} />
        <meta name="keywords" content={metadata.words} />
        <meta name="robots" content="index" />

        <meta property="og:title" content={metadata.name}></meta>
        <meta property="og:site_name" content={metadata.name}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content={"https://events.arctic.ws"}></meta>
        <meta
          property="og:description"
          content={`'Collective Actions Towards Climate Sustainability: '${metadata.memo}`}
        ></meta>
        <meta property="og:image" content={metadata.media.link}></meta>

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:creator" content="@arcticwx"></meta>
        <meta name="twitter:site" content="@arcticwx"></meta>
        <meta name="twitter:title" content={metadata.name}></meta>
        <meta name="twitter:image" content={metadata.media.link}></meta>
        <link rel="canonical" href={`https://events.arctic.ws`} ></link>
        <link rel="shortlink" href={`https://events.arctic.ws`}></link>
      </Helmet>

      <main className={``}>
        <div
          className="back-color-lite"
          style={{
            backgroundImage: `url(${banner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "",
            minHeight: '100vh'
          }}
        >
          <WebbDividerSmall />
          <WebbDividerMedium />
          <div className="text-center text-color-wite">
            <i
              className="bi bi-suit-club-fill d-none"
              style={{ fontSize: "4.5rem" }}
            ></i>
            <img src={icon} className="rounded-xx" alt='icon' style={{height: '4rem', width:'4rem'}}></img>
          </div>
          <WebbDividerSmall />
          <h1 className="h2 text-bold text-center text-color-wite">
            The Arctic Protocol <br></br>
            {/* Lets make a Carbon Neutral <br></br> */}
            {/* Algorand India Summit<br></br> */}
            {/* Hyderabad, on December 7-8, 2024 */}
          </h1>

          <WebbDividerSmall />
          <WebbDividerMedium />
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <p className="text-lead text-color-wite">
                  The Arctic region is warming at an alarming rate, 
                  significantly impacting global climate patterns and biodiversity. 
                  The "Arctic Protocol," is a Collective Action Framework
                  to address climate change, limit temperature 
                  rise to 1.5 degrees Celsius in the Arctic, and promote sustainable 
                  activities worldwide.
                </p>

                <WebbDividerMedium />
                

                <div className="row row-cols-1 row-cols-md-2">
                  
                <div className="col">
                  <p className="text-lead text-color-wite"> 
                    Reduce greenhouse gas emissions through international cooperation and individual actions.
                  </p>
                </div>
                <div className="col">
                  <p className="text-lead text-color-wite"> 
                    Build resilience in Arctic communities and ecosystems to climate change impacts.
                  </p>
                </div>
                <div className="col">
                  <p className="text-lead text-color-wite"> 
                    Promote environmentally friendly practices in the Arctic and globally.
                  </p>
                </div>
                <div className="col">
                  <p className="text-lead text-color-wite"> 
                    Encourage individuals to offset their carbon footprint through various sustainable activities.
                  </p>
                </div>
                  
                </div>

              </div>
              <div className="col-md-3"></div>
            </div>
          </div>

        </div>
      </main>
    </>
  );
}
