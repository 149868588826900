// content
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import WebbDividerMedium from "../../src/content/webx/webb-divider-md";
import WebbDividerSmall from "../../src/content/webx/webb-divider-sm";
import ImpactSummitModule from "../content/summit/impact-summit";

const media = '/terano-main.png'
const banner = 'https://firebasestorage.googleapis.com/v0/b/finnovate99.appspot.com/o/terano.site%2Fterano-impact-summit-carbon-offset-net-zero.png?alt=media&token=9fb8fd41-4525-4d6f-8b17-d075a56fb510'

const metadata = {
  name: 'Algorand Impact Summit 2024 India Hyderabad',
  memo: process.env.NEXT_PUBLIC_WEBB_SITE_LINE,
  words: process.env.NEXT_PUBLIC_WEBB_SITE_WORDS,
  banner: {link: banner}, // {link: process.env.NEXT_PUBLIC_WEBB_SITE_MEDIA},
  media: {link: banner}, // {link: process.env.NEXT_PUBLIC_WEBB_SITE_MEDIA},
  created: '2024-09-01T05:00:00.000Z',
  modified: '2024-09-01T06:00:00.000Z',
  domain: 'main'
}

export default function Content() {

  const [user, setuser] = useState({
    name: '', mail: '', item: ''
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [count, setCount] = useState(0)

  const getCount = (number) => {
    setCount(number)
  }

  return (
    <>
      <Helmet>
        <title>{`${metadata?.name} • ${metadata.memo}`}</title>
        <meta
          name="description"
          content={`'Buy and Sell Regsitry Verified Carbon Credits: '+${metadata.memo}`}
        ></meta>
        <meta property="og:image" content={metadata.media.link} />
        <meta name="keywords" content={metadata.words} />
        <meta name="robots" content="index" />

        <meta property="og:title" content={metadata.name}></meta>
        <meta property="og:site_name" content={metadata.name}></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:url"
          content={
            "https://terano.io/events/algorand-impact-summit-india-hyderabad-2024"
          }
        ></meta>
        <meta
          property="og:description"
          content={`'Buy and Sell Regsitry Verified Carbon Credits: '+${metadata.memo}`}
        ></meta>
        <meta property="og:image" content={metadata.media.link}></meta>

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:creator" content="@arcticwx"></meta>
        <meta name="twitter:site" content="@arcticwx"></meta>
        <meta name="twitter:title" content={metadata.name}></meta>
        <meta name="twitter:image" content={metadata.media.link}></meta>

        <link
          rel="canonical"
          href={`https://terano.io/events/algorand-impact-summit-india-hyderabad-2024`}
        ></link>
        <link
          rel="shortlink"
          href={`https://terano.io/events/algorand-impact-summit-india-hyderabad-2024`}
        ></link>
      </Helmet>

      <main className={``}>
        <div
          className="back-color-lite"
          style={{
            backgroundImage: `url(${media})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "150vh",
            // minHeight: '100vh'
          }}
        >
          <WebbDividerSmall />

          <div className="text-center text-color-wite">
            <i
              className="bi bi-suit-club-fill"
              style={{ fontSize: "4.5rem" }}
            ></i>
          </div>

          <h1 className="h2 text-bold text-center text-color-wite">
            Offset Your Carbon Footprint <br></br>
            {/* Lets make a Carbon Neutral <br></br> */}
            {/* Algorand India Summit<br></br> */}
            {/* Hyderabad, on December 7-8, 2024 */}
          </h1>

          <WebbDividerSmall />
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 ">
                <div className="back-color-wite rounded-xd p-3 m-3">
                  <ImpactSummitModule />   
                </div>

                <WebbDividerMedium />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 ">
                <div className="text-color-wite text-center rounded-xd p-3 m-3">
                  <p className="m-0 text-normal text-bold">Powered by Terano</p>
                  <p className="m-0">
                    India's First Carbon Market for Carbon Credits, IREC, CDR
                    and 24x7 Carbon Free Energy
                  </p>
                </div>

                <WebbDividerSmall />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
