import React from "react";
import { useEffect, useState } from "react";
import WebbDividerSmall from "../webx/webb-divider-sm";
import CarbonFootprint from "./carbon-footprint";

const TransportationModule = (props) => {
  const {
    handleBack,
    handleNext,
    stepchange,
    stepCount,
    setLocaltravelCarbon,
  } = props;
  const [loading, setLoading] = useState(true);
  const [icao, setIcao] = useState({});
  const [memo, setMemo] = useState("");
  const [formValues, setFormValues] = useState({
    numPassengers: 1,
    distance: 0,
  });

  const fetchdata = async () => {
    setLoading(true);
    const datx = {
      distance: formValues.distance,
      passenger_count: formValues.numPassengers,
    };
    
    const result = await CarbonFootprint({
      data: datx,
      srvc: "******",
    });
    if (result.stat) {
      setIcao(result.data);
    }
    setLoading(false);
  };

  const handleCalculate = () => {
    // fetchdata();
    setLocaltravelCarbon(parseInt(formValues.distance) * 0.2);
    handleNext();
  };
  //   console.log("CALCULATE", icao);

  const handleChange = async (key, val) => {
    setFormValues({ ...formValues, [key]: val });
  };

  return (
    <>
      <div className="container-fluid border rounded ">
        <div className="container pb-4">
          <div className=" align-items-center gap-5 mt-4">
            <div>
              <form>
                <div className="form-group ">
                  <label
                    htmlFor="distance"
                    className="text-bold"
                    style={{ color: "black", fontSize: "15px" }}
                  >
                    Driven (km)
                  </label>
                  <input
                    type="number"
                    className="form-control mt-1 mb-3 tex-small"
                    min="1"
                    step="1" // Ensures only integers are allowed
                    onInput={({ target }) => {
                      target.value = target.value.replace(/[^0-9]/g, ""); // Removes non-numeric characters
                      if (parseInt(target.value, 10) < 1) {
                        target.value = ""; // Clears the input if it's less than 1
                      }
                    }}
                    onChange={({ target }) => {
                      const value = parseInt(target.value, 10);
                      handleChange("distance", value || "");
                    }}
                    placeholder="Enter distance(km)"
                  />
                </div>

                {/* <hr /> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="d-flex align-items-center justify-content-between">
          {stepCount !== 0 && (
            <button
              onClick={() => handleBack()}
              className="btn rounded-xx btn-outline-secondary text-small mt-3 w-10 "
              type="submit"
            >
              Back
            </button>
          )}

          <button
            onClick={handleCalculate}
            className="btn rounded-xx btn-success text-small float-end mt-3 w-10 "
            type="submit"
            disabled={!formValues.distance}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default TransportationModule;
