// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { useAuth } from '../context/authcontext'

import Main from "../views.xz/main-xz";
import Events from "../views.events/algorand-impact-summit-india-hyderabad-2024";
import TieDelhi from "../views.events/tie-delhi-ncr-sustainability";

const routes = [
  {
    route: "/",
    content: <Main />,
    auth: false,
  },
  {
    route: "/events/algorand-impact-summit-india-hyderabad-2024",
    content: <Events />,
    auth: false,
  },
  {
    route: "/events/tie-delhi-ncr-sustainability",
    content: <TieDelhi />,
    auth: false,
  },  
];

export default function RouteX() {
  // const { user } = useAuth()

  return (
    <Routes>
      {routes.map((item, i) => (
        // true ? (
        //   <Route
        //     key={i}
        //     path={item.route}
        //     element={!user ? <Navigate to='/' replace /> : item.content}
        //   />
        // ) :
        <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}
